import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const pageJsonLD = {
    "@type": "LocalBusiness",
    name: "bluesnap.io",
    description: "Bluesnap is a premium domain registrar specialising in the Nordic TLDs .SE, .NU and .FI. We provide exceptional services in all areas of domain management, from new domain registration to DNS management and brand protection.",
    telephone: "+46 8 120 16 ROI (764)",
    email: "hello@bluesnap.io",
    priceRange: "$",
    url: "https://bluesnap.io",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Stockholm",
      addressRegion: "Sweden",
      streetAddress: "Drottninggatan 71C",
      postalCode: "11136",
    }
  }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(pageJsonLD)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
